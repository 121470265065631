import { withStyles } from '@material-ui/core/styles';
import Button from '../../Button';

const SubscribeButton = withStyles({
  root: {
    borderTopRightRadius: '7px',
    borderBottomRightRadius: '7px',
  },
})(Button);

export default SubscribeButton;
