import { withStyles } from '@material-ui/core/styles';
import Button from '../../Button';

const ViewAllButton = withStyles({
  root: {
    borderBottom: '2px solid',
    padding: '5px 45px',
    borderRadius: '0',
  },
  label: {
    textTransform: 'capitalize',
    color: '#1B120F',
  },
})(Button);

export default ViewAllButton;
