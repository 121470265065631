import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import Layout from '../components/Layout';
import ViewAllButton from '../components/Button/ViewAllButton';
import SubscribeButton from '../components/Button/SubscribeButton';
import * as ROUTES from '../constants/routes';

import MagicBulletsLeft from '../components/MagicBullets/MagicBulletsLeft';
import MagicBulletsRight from '../components/MagicBullets/MagicBulletsRight';


const itemsLeft = [
  {
    type: 'twoB',
    width: 120,
    x: -6,
    y: 10,
  },
  {
    type: 'three',
    width: 150,
    x: -10,
    y: 50,
  },
  {
    type: 'circle',
    width: 50,
    x: 10,
    y: 90,
  },
  {
    type: 'circleBig',
    width: 80,
    x: 13,
    y: 100,
  },
  {
    type: 'circleBig',
    width: 80,
    x: -4,
    y: 120,
  },
];

const itemsRight = [
  {
    type: 'one',
    width: 120,
    x: -6,
    y: 10,
  },
  {
    type: 'twoA',
    width: 150,
    x: -9,
    y: 50,
  },
  {
    type: 'circleBig',
    width: 80,
    x: -5,
    y: 100,
  },
  {
    type: 'twoA',
    width: 150,
    x: -9,
    y: 130,
  },
];

const LandingPage = () => (
  <Fragment>
    <Box position="relative">
      <MagicBulletsLeft items={itemsLeft} />
      <MagicBulletsRight items={itemsRight} />
      <Typography variant="body1" paragraph>
        The Landing Page is open to everyone, even though the user
        isn't signed in. Ea officia esse est do dolore incididunt
        nulla esse sit aliquip sit duis velit. Do sunt sit incididunt
        veniam voluptate velit adipisicing consequat labore consequat
        veniam quis. Veniam aliqua non officia sunt est exercitation
        ipsum nostrud nostrud fugiat commodo. Cupidatat enim deserunt
        culpa quis id. Ad exercitation id incididunt cillum ea sunt
        anim voluptate. Non sunt cillum nostrud non aute. Dolor
        reprehenderit eu est laborum consectetur ad magna qui dolor
        mollit sunt. Reprehenderit tempor et reprehenderit eu ad
        laborum laboris cillum aute esse nulla deserunt. Esse
        consequat nisi dolor Lorem sit laborum ea aliqua. Cupidatat
        sint cupidatat et aute. Cillum ea sit duis anim enim. Eu
        ullamco deserunt officia aliqua magna dolore fugiat. Sint aute
        culpa exercitation officia ad ut laboris. Sint commodo laboris
        sunt consequat est aute excepteur dolore anim. Dolore
        adipisicing esse id ipsum elit non id. Lorem quis minim
        excepteur excepteur irure esse et adipisicing est sint mollit
        consectetur. Occaecat ad aute veniam labore eiusmod laborum
        cupidatat. Fugiat sunt consectetur aliqua ex aliqua dolore do
        tempor irure. Esse veniam consectetur labore veniam
        adipisicing irure non culpa elit do ut. Voluptate eiusmod
        veniam laborum fugiat est non esse eiusmod reprehenderit
        magna. Dolore in exercitation in laboris Lorem sunt tempor est
        cupidatat nostrud ad do sint. Reprehenderit commodo quis magna
        aliqua. Non
      </Typography>

      <ViewAllButton to={ROUTES.YOURCOURSES} color="primary">
        View All
      </ViewAllButton>
      <SubscribeButton color="primary" variant="contained">
        <ChevronRightRoundedIcon />
      </SubscribeButton>
    </Box>
  </Fragment>
);

export default () => (
  <Layout>
    <LandingPage />
  </Layout>
);
