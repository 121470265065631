import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  magicBullets: {
    pointerEvents: 'none',
    zInted: '150',
  },
});

const One = ({ width, x, y }) => (
  <Box mt={y} mr={x} right={0} position="absolute">
    <img src={'/fx/one.png'} alt="fx" width={width} />
  </Box>
);

const TwoA = ({ width, x, y }) => (
  <Box mt={y} mr={x} right={0} position="absolute">
    <img src={'/fx/two-a.png'} alt="fx" width={width} />
  </Box>
);

const TwoB = ({ width, x, y }) => (
  <Box mt={y} mr={x} right={0} position="absolute">
    <img src={'/fx/two-b.png'} alt="fx" width={width} />
  </Box>
);

const Three = ({ width, x, y }) => (
  <Box mt={y} mr={x} right={0} position="absolute">
    <img src={'/fx/three.png'} alt="fx" width={width} />
  </Box>
);

const Circle = ({ width, x, y }) => (
  <Box mt={y} mr={x} right={0} position="absolute">
    <img src={'/fx/circle.png'} alt="fx" width={width} />
  </Box>
);

const CircleBig = ({ width, x, y }) => (
  <Box mt={y} mr={x} right={0} position="absolute">
    <img src={'/fx/circle-big.png'} alt="fx" width={width} />
  </Box>
);

const Empty = () => <Box></Box>;

const getComponent = item => {
  console.log('item', item);
  switch (item.type) {
    case 'one':
      return One;
    case 'twoA':
      return TwoA;
    case 'twoB':
      return TwoB;
    case 'three':
      return Three;
    case 'circle':
      return Circle;
    case 'circleBig':
      return CircleBig;
    default:
      return Empty;
  }
};

const MagicBulletsRight = ({ items }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.magicBullets}
      position="absolute"
      overflow="hidden"
      top={0}
      width="100%"
      height="100%"
    >
      {items.map((item, index) => {
        const ItemComponent = getComponent(item);
        return <ItemComponent key={index} {...item} />;
      })}
    </Box>
  );
};

export default MagicBulletsRight;
